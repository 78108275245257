/**
 * this is manually-generated, not using the amplify cli but
 * the output from the cdk subproject.
 */ 
const config = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_LRaKgfIP5",
    userPoolWebClientId: "5bfb0q2qsgflr91bhsise2vil8"
  },
}

export default config;
