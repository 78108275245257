import { Auth } from 'aws-amplify';
import $ from 'jquery';
import { Medialibrary } from './webpack-medialibrary';

const TARGET_ENV = 'QA';
const TARGET_URL = 'https://s3-us-west-2.amazonaws.com/test-materials/medialibrary/';
//const TARGET_ENV = 'US';
//const TARGET_URL = 'https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/';

//local no funciona con python httpSimpleServer .. por CORS entre el https y el http
//esta ruta es para el modulo http-server de node.js, require el slash final:
//const TARGET_ENV = 'local';
//const TARGET_URL = 'http://localhost:8080/client/';

let medialibapi = null;

/**
 * if login is valid, returns true
*/ 
async function login(cognito_token) {
  const data = await Auth.currentSession();
  // the token we are interested in: 
  // (trying idToken instead of accessToken to have access to email, phone)
  const token = data.idToken.jwtToken;
  medialibapi = new Medialibrary.API();
  try {
    await medialibapi.init({
      env: TARGET_ENV,
      token,
      material: 'flashcards',
      platform: 'cognito'
    });
    return true;
  } catch(err){
    console.log(err);
    if (err.code === 402) {
      // this one is fired on login_cognito after 30 days..
      console.log("Demo expired!");
    } else {
      // unknown error.. continue..
      console.log("Unknown error..");
    }
    return false;
  }
}

/**
 * return meta information of the user
*/ 
async function getMeta(){
  const result = await medialibapi.createOp({op: "cognito:get:meta"});
  return result.params;
}

/**
 * persist information about the user
*/ 
async function saveMeta(meta){
  const user = await Auth.currentAuthenticatedUser();
  const userattrs = await Auth.userAttributes(user);
  function getattr(name){
    const userattr = userattrs.find(attr => attr.Name === name);
    return userattr?userattr.Value:null;
  }
  meta.email = getattr('email');
  meta.phone_number = getattr('phone_number');
  await medialibapi.createOp({
    op: "cognito:update:meta",
    meta
  });
}

/**
 * @param react mount point, from useRef hook..
*/ 
async function initVM(mountpoint) {
  const data = await Auth.currentSession();
  // important! notice the second paramenter medialibapi,
  // that is the way how we avoid a second auth call,
  // that will fire against unitusti, then failing.
  window.Mundopato.MediaLibrary.load({
    sel: 'div.vm_container',
    token: data.idToken.jwtToken,
    material: 'flashcards',
    platform: 'cognito',
    mode: 'manager',
    url: TARGET_URL,
    env: TARGET_ENV
  }, medialibapi);
}

export {
  login,
  initVM,
  getMeta,
  saveMeta,
}
