import "antd/dist/antd.css";
import React, { useEffect } from 'react';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
// import { HashRouter, Route, Link, Switch } from 'react-router-dom';
import {Auth , Hub } from 'aws-amplify';
import { Layout, Row, Col} from 'antd';
import VirtualMaterials from './VirtualMaterials';
import MundopatoFooter from './MundopatoFooter';
import './App.css';

const { Header, Content } = Layout;
/*
function Router() {
  return (
    <div>
      <HashRouter>
        <div style={topLevelContainerStyle}>
          <div style={headerStyle}>
            <a href="/" style={homeLinkStyle}>
              <p style={titleStyle}>Virtual Materials</p>
              </a>
          </div>
          <nav style={navStyle}>
            <Link to="/" style={linkStyle}>
              View all rooms
            </Link>
            <Link to="/profile" style={linkStyle}>
              Profile
            </Link>
            <Link to="/vm" style={linkStyle}>
              Virtual Materials
              </Link>
                </nav>
        </div>
        <div style={mainViewContainerStyle}>
          <Switch>
            <Route exact path="/">
             <AmplifySignOut />
              <VirtualMaterials />
            </Route>
            <Route path="/chat/:name/:id">
              <p>chat</p>
            </Route>
            <Route exact path="/vm">
              <VirtualMaterials />
            </Route>
          </Switch>
        </div>
      </HashRouter>
    </div>
  )
}*/
const vmheader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: '20px 0 0 5px',
  backgroundColor: 'rgba(222, 233, 237, 0.5)',
  height: 'auto',
  marginBottom: '10px',
};

const cssBody = {
  /*display: 'flex',
  flexDirection: 'column',*/
  height: '100vh',
}

const cssLogo = {
  maxWidth: '320px'
}

const cssSpacer = {
  flex: '1 1 auto'
}

const cssBorderCol = {
  backgroundColor: 'pink'
};

const cssContentCol = {
  backgroundColor: 'lightgreen'
};

function App() {
  
  const [user, updateUser] = React.useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(() => console.log('No signed in user.'));

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return updateUser(data.payload.data);
        case 'signOut':
          return updateUser(null);
      }
    });
  }, []);

  if (user) {
    return (

<Layout style={cssBody}>
  <Header style={vmheader}>
    <img src='Logo-Mundo-Pato+2017.png' style={cssLogo}></img>          
    <h2>Welcome, { user && user.username }</h2>
    <AmplifySignOut />
  </Header>
    <Content>
      <Row>
        <Col style={{cssBorderCol}} span='1'></Col>
        <Col style={{cssContentCol}} span='22'>
          <VirtualMaterials />
         </Col>
        <Col style={{cssBorderCol}}  span='1'></Col>
      </Row>
  </Content>
  <MundopatoFooter/>
</Layout>
    )
  }
  else {
    return (
      <div>
       <div style={vmheader}>
          <img src='Logo-Mundo-Pato+2017.png' style={cssLogo}></img>          
          <h2>Virtual Materials Demo</h2>
       </div>
      <AmplifyAuthenticator>
      <AmplifySignUp/>
      </AmplifyAuthenticator>
      <MundopatoFooter/>
      </div>
    )
  }
}

export default App;
