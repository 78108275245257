import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Card } from 'antd';
import MetaInfo from './MetaInfo';
import MaterialContainer from './MaterialContainer';
import * as security from './Security';

function VirtualMaterials() {
  const [user, setUser] = useState(null);
  //instead of boolean, lets use 0: validating.. 1: valid, 2: invalid
  const [validDemo, updateValidDemo] = React.useState(0);

  useEffect(() => {
    checkUser();
  }, []);

  /**
  */ 
  async function checkUser() {
    const user = await Auth.currentAuthenticatedUser();
    setUser(user);
    if(user) {
      const success = await security.login();
      if(success) {
        const meta = await security.getMeta();
        if(meta) {
          updateValidDemo(1);
        } else {
          //means we need to capture meta info
          updateValidDemo(4);
        }
      } else {
        updateValidDemo(2);
      }
    }
  }

  /**
  */ 
  function onMetaSaved(){
    updateValidDemo(1);
  }

  if(validDemo === 0) {
    return (
      <div><h2>Validating..</h2></div>
    )
  } else if(validDemo === 1) { 
    return (
      <MaterialContainer></MaterialContainer>
    )
  } else if(validDemo === 2){
    return (
      <Card>
        <h2>Demo Expired</h2>
          <p>Your Demo period for Virtual Materials had expired.</p>
          <h3>Thank you for using our product!</h3>
          <p> We would like to know more about your experience, please let us know <a href='#'>here</a></p>
      </Card>
    )
  } else if (validDemo === 4) {
    return (<MetaInfo onMetaSaved={onMetaSaved}/>)
  }
}

export default VirtualMaterials;
