import React, { useState } from 'react';
import {Card, Form, Input, Button, message, Spin, Select } from 'antd';
import * as security from './Security';
import countries from './countries';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

/**
 * A form that collects information about the user
*/ 
const MetaInfo = ({onMetaSaved}) => {

  const [saving, setSaving] = useState(false);
  const [countriesList, setCountries] = useState(countries);

  const onFinish = async (values) => {
    try {
      setSaving(true);
      await security.saveMeta(values);
      message.success('Demo activated!');
      setSaving(false);
      onMetaSaved();
    } catch(err) {
      console.log(err);
      message.error('Error activating the Demo. Please try again.');
      setSaving(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card>
      <h2>Demo Activation</h2>
        <p>Please provide the following information in order to activate your 30-days trial period:</p>
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Full Name"
        name="fullname"
        rules={[
          {
            required: true,
            message: 'Please input your full name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
        
        <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Please input your title!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Company Name"
        name="company"
        rules={[
          {
            required: true,
            message: 'Please input your company name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Website"
        name="website"
        rules={[
          {
            required: true,
            message: 'Please input your website!',
          },
        ]}
      >
      <Input />
        </Form.Item>


      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: 'Please input your country!',
          },
        ]}
      >
      <Select >
        {
          (countriesList && countriesList.map)?countriesList.map(country => <Option key={country.code} value={country.code}>{country.name}</Option>):null
        }
      </Select>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" 
          htmlType="submit" 
          disabled={saving}>
          Activate my Demo!
        </Button>
        {saving?<Spin size="large"></Spin>:null}
      </Form.Item>
        </Form>
     </Card>
  );
};

export default MetaInfo;
