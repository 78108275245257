import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'antd';
import { Auth } from 'aws-amplify';
import * as security from './Security';

function MaterialContainer() {
  const [user, setUser] = useState(null);

  const mount = useRef(null);
  let isMounted = true;

  useEffect(() => {
    checkUser();
    return () => {
      isMounted = false;
    }
  }, []);

  /**
  */ 
  async function checkUser() {
    const user = await Auth.currentAuthenticatedUser();
    if(!isMounted) return;
    setUser(user);
    if(user) {
      const success = await security.login();
      if(success) {
        await security.initVM(mount.current);
      }
    }
  }

  return(
    <Card className='vm_container' ref={mount}></Card>
  )
}

export default MaterialContainer;
