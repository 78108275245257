import { Layout} from 'antd';
const { Header, Footer, Content } = Layout;

const cssFooter  ={
  backgroundColor: 'rgba(222, 233, 237, 0.5)',
  display: 'flex',
  flexDirection: 'row',
  /*padding: '20px 10px 20px 20px',*/
  justifyContent: 'space-between'
}

const cssAnchor = {
  textDecoration: 'none',
  color: 'inherit'
}

function MundopatoFooter() {
  return (
    <Footer style={cssFooter}>
      <div><a style={cssAnchor} href='https://www.mundopato.com/privacy-policy'>Privacy Policy</a></div>
      <div>© 2010-2020 Mundo Pato Inc.</div>
      <div>Report Content Violation</div>
    </Footer>
  );
}
export default MundopatoFooter;
